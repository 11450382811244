.decision {
  padding: 25px 0 0;

  &-container {
    position: relative;

    @include tablets {
      @include flex(column, center, center);
      min-height: 565px;
    }

    &_picture {
      margin-bottom: 80px;
      @include tablets {
        margin-bottom: 100px;
      }
      @include laptops {
        margin-bottom: 60px;
      }
    }
  }

  &-media {
    @include flex(column, center, center);
    position: relative;
    width: 200px;
    height: 415px;
    margin: 0 auto 50px;

    @include tablets {
      width: 217px;
      height: 450px;
    }
    @include laptops {
      position: absolute;
      top: 50%;
      right: 36px;
      transform: translateY(-50%);
      width: 244px;
      height: 506px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 177px;
      height: 2px;
      background-color: #f6fbfe;

      @include tablets {
        bottom: 32px;
        width: 192px;
      }
      @include laptops {
        bottom: 38px;
        width: 214px;
      }
    }

    &__video {
      width: 90%;
      height: 85%;
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &-picture {
    position: relative;
    width: 100%;
    height: 100vw;
    max-height: 680px;

    &__stripe {
      height: 100%;
      width: 25%;
      background-color: $yellow;

      @include tablets {
        max-width: 120px;
      }
      @include laptops {
        max-width: 230px;
      }
    }

    &__man {
      width: 60%;
      position: absolute;
      top: 80px;
      left: 26%;

      @include tablets {
        left: 13%;
        width: 475px;
        height: 404px;
      }
      @include laptops {
        top: 50px;
        left: 13%;
      }
      @include laptopsDPI {
        top: 140px
      }
    }

    &__trees {
      width: 65%;
      position: absolute;
      top: 0px;
      left: 0;

      @include tablets {
        width: 372px;
        height: 390px;
      }
      @include laptops {
        top: -30px;
      }
      @include laptopsDPI {
        top: 70px;
      }
    }
  }

  &-content {
    position: relative;
    background-color: $bg-light-gray;
    padding: 20px 0 10px;
    width: 100%;
    overflow: hidden;

    &__title {
      max-width: 246px;
      line-height: 34px;

      @include tablets {
        font-size: 40px;
        letter-spacing: 1.4px;
        line-height: 60px;
        max-width: 100%;
        margin-bottom: 40px;
        padding-left: 52%;
      }
    }

    &__text {
      margin-bottom: 15px;

      @include tablets {
        font-size: 20px;
        line-height: 36px;
        padding-left: 52%;
      }
    }

    &__slider {
      width: calc(100% + 32px);
      margin-left: -16px;

      @include tablets {
        position: absolute;
        top: -20px;
        width: 100%;
        margin-left: 0;
      }
      @include laptops {
        width: 205px;
      }

      &_1 {
        margin-bottom: -15px;

        @include tablets {
          left: 25px;
        }

        .slick-track {
          left: 95px;
          top: 0;

          @include tablets {
            left: 8px;
            top: -60px;
          }
        }
      }

      &_2 {
        @include tablets {
          left: 190px;
        }
        @include laptops {
          left: 233px;
        }

        .slick-track {
          left: 45px;
          top: 0;

          @include tablets {
            left: 0;
            top: 80px;
          }
        }
      }

      .slider-item {
        height: 130px;
        max-width: 130px;
        line-height: 0;

        @include tablets {
          height: 150px;
          max-width: 150px;
        }
        @include laptops {
          height: 190px;
          max-width: 190px;
        }
      }

      .slick-list {
        padding-top: 10px;

        @include tablets {
          padding-top: 0;
          overflow: visible;
        }
      }

      .slick-slide {
        transition: all .2s ease-in-out;

        &:hover {
          @include laptopsDPI {
            transform: scale(1.15);
          }
        }
      }
    }
  }

}