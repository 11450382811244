// Colors
$progress-gray: #EDEDED;
$bg-light-gray: #F9FBFF;
$base-text-color: #3E3E3E;
$light-gray: #989898;
$gray: #848484;
$black: #000000;
$white: #ffffff;
$blue: #708EFF;
$darkBlue: blue;
$red: #F67B7B;
$yellow: #F0D12E;
$green: #0ABA7F;

// Breakpoints
$desktopFHD: 1920px;
$desktop: 1440px;
$laptopsDPI: 1280px;
$laptops: 960px;
$tablets: 600px;
$phones: 480px;
$vertical: 321px;