.header-title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.privacy-policy,
.use-terms {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
  &__inner-item {
    font-size: 14px;
    font-weight: normal;
    list-style-type: disc;
  }
  &__inner {
    margin-left: 40px;
  }
  &__list {
    counter-reset: item;
  }
  &__title {
    padding: 15px 0 0;
    font-size: 16px;
    font-weight: bold;
    list-style-type: decimal;
    display: block;
    &::before {
      content: counters(item, ".") ". ";
      counter-increment: item
    }
  }
  &__text {
    font-size: 14px;
    font-weight: normal;
    text-indent: 20px;
  }
}

.preview {
  &-list {
    margin-left: 25px;
    counter-reset: item;
    &__item {
      list-style-type: decimal;
      display: block;
      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item
      }
    }
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
  }
}