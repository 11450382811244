.index {
  padding: 65px 0 70px;

  @include tablets {
    padding: 95px 0 100px;
  }
  @include laptopsDPI {
    padding: 58px 0 80px;
    overflow: hidden;
  }

  &-container {
    @include flex(column, flex-start, center);

    @include laptopsDPI {
      @include flex(row, space-between, flex-start);
      max-width: 1090px;
    }
  }

  &-content {
    @include flex(column, flex-start, center);
    margin-bottom: 30px;

    @include tablets {
      margin-bottom: 20px;
    }

    @include laptopsDPI {
      @include flex(column, flex-start, flex-start);
      max-width: 40%;
      padding-top: 90px;
      margin-bottom: 0;
    }
  }

  &-store {
    &__link {
      display: inline-block;
      &:first-child {
        margin-right: 25px;
      }
      @include tablets {
        &:first-child {
          margin-right: 60px;
        }
      }
      @include laptopsDPI {
        &:first-child {
          margin-right: 50px;
        }
      }
    }
    &__img {
      height: 30px;
      @include tablets {
        height: 40px;
      }
      @include laptops {
        height: 50px;
      }
    }
  }

  &-text {
    line-height: 28px;
    text-align: center;
    max-width: 325px;
    margin-bottom: 25px;

    @include tablets {
      font-size: 20px;
      line-height: 36px;
      max-width: 585px;
      margin-bottom: 45px;
    }
    @include laptopsDPI {
      text-align: left;
      max-width: 410px;
      margin-bottom: 55px;
    }
  }

  &-created {
    color: $blue;
    font-weight: 400;
    letter-spacing: 0.45px;

    @include tablets {
      letter-spacing: 0.64px;
    }

    span {
      text-transform: uppercase;
    }
  }

  &-button {
    display: block;
    color: $white;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: .75px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 35px;
    border: none;
    border-radius: 8px;
    background-color: $blue;
    margin-bottom: 20px;

    @include laptopsDPI {
      position: relative;
      color: $blue;
      font-size: 14px;
      line-height: 14px;
      padding: 12px 28px;
      margin-bottom: 30px;
      background-color: $white;
      box-shadow: 0px 4px 8px rgba(197, 197, 197, 0.5);
      overflow: hidden;
      z-index: 1;
      transition: all .3s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        width: 22px;
        background-color: $blue;
        transition: all .3s ease-in-out;
      }

      &:hover {
        color: $white;
        box-shadow: 0px 4px 8px rgba(197, 197, 197, 0.8);

        &:before {
          width: 100%;
        }
      }
    }
  }

  &-picture {
    position: relative;
    width: 285px;
    height: 226px;
    margin: 0 auto;

    @include tablets {
      width: 100%;
      max-width: 650px;
      height: 515px;
    }
    @include laptopsDPI {
      width: 635px;
      max-width: 100%;
      height: 525px;
      margin: 0;
    }

    &__slide {
      position: absolute;
      top: 50px;
      left: 65px;
      width: 152px;
      height: 90px;

      @include tablets {
        top: 115px;
        left: 155px;
        width: 350px;
        height: 204px;
      }
    }

    &__book-man {
      position: absolute;
      top: 25px;
      left: 95px;
      width: 60px;
      height: 71px;

      @include tablets {
        top: 86px;
        left: 245px;
        width: 131px;
        height: 158px;
      }
    }

    &__batut {
      position: absolute;
      bottom: 47px;
      left: 0;
      width: 115px;
      height: 35px;

      @include tablets {
        bottom: 108px;
        left: 0;
        width: 260px;
        height: 77px;
      }
    }

    &__green-man {
      position: absolute;
      top: 95px;
      left: 14px;
      width: 80px;
      height: 60px;

      @include tablets {
        top: 165px;
        left: 38px;
        width: 181px;
        height: 138px;
      }
      @include desktop {
        animation: green-man 3.5s infinite;
      }
    }

    &__bike-man {
      position: absolute;
      bottom: 0;
      left: 100px;
      z-index: 10;
      width: 116px;
      height: 115px;

      @include tablets {
        bottom: 0;
        left: 190px;
        width: 262px;
        height: 260px;
      }
    }

    &__blue-girl {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      width: 117px;
      height: 128px;
      transform-origin: top right;

      @include tablets {
        top: 0;
        right: 0;
        width: 264px;
        height: 290px;
      }
      @include desktop {
        animation: blue-girl 3s ease-in-out infinite;
      }
    }
  }
}