.footer {
  margin-top: 80px;
  width: 100%;
  height: 40px;
  background-color: $white;
  padding: 0 21px;

  &-container {
    @include flex(column, center, flex-start);

    @include tablets {
      @include flex(row, space-between, center);
    }

    @include laptops {
      @include flex(row, space-between, center);
    }
  }

  &-logo {
    margin-top: 80px;
    margin-bottom: 30px;

    @include tablets {
      display: contents;
      width: 147px;
      height: 30px;
      margin-bottom: 0;
      margin-top: 0;
    }
    &__img {
      display: block;
      width: 98px;
      height: 20px;
      background: url('/img/logo-desktop.svg') center no-repeat;
      background-size: contain;
      z-index: 100;

      @include laptopsDPI {
        width: 147px;
        height: 30px;
      }
    }
  }

  &-rules {
    margin-top: 180px;
    margin-bottom: 21px;
    text-align: center;

    @include tablets {
      margin-top: 30px;
    }
    &__link {
      display: inline-block;
      font-weight: bold;
      line-height: 14px;
      font-size: 12px;
      color: #848484;
      &:first-child {
        margin-right: 39px;
      }

      @include laptopsDPI {
        &:first-child {
          margin-right: 54px;
        }
      }
    }
  }

  &-list {
    @include flex(column, center, flex-start);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: transparent;
    font-size: 12px;

    @include tablets {
      @include flex(row, space-between, center);
    }

    @include laptopsDPI {
      @include flex(row, space-between, center);
    }

    &__item {
      margin-bottom: 30px;
      cursor: pointer;

      @include tablets {
        position: relative;
        margin-left: 50px;
        margin-bottom: 0;
      }
    }

    &__link {
      color: $gray;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: .6px;
      text-transform: uppercase;

      @include tablets {
        font-size: 14px;
        line-height: 17px;
      }

      @include laptopsDPI {
        color: $gray;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: .6px;
        text-transform: uppercase;
      }
    }
  }
}