body {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.body-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  color: $base-text-color;
}

.wrapper {
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 960px;
  padding: 0 16px;
  margin: 0 auto;

  @include tablets {
    padding: 0 36px;
  }
  @include laptopsDPI {
    padding: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-title {
  font-size: 24px;
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 30px;

  @include tablets {
    font-size: 40px;
    margin-bottom: 40px;
  }

  @include laptopsDPI {
    font-size: 48px;
  }
}

.block-text {
  text-align: left;
  @include tablets {
    font-size: 20px;
  }
}

.font-weight-900 {
  font-weight: 900;
}