.header {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: $white;

  @include laptopsDPI {
    height: 60px;
    box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.5);
  }

  &-container {
    @include flex(row, space-between, center);
  }

  &-logo {
    display: block;
    width: 20px;
    height: 30px;
    background: url('/img/logo-mobile.svg') center no-repeat;
    background-size: contain;
    z-index: 100;

    @include laptopsDPI {
      width: 147px;
      height: 30px;
      background: url('/img/logo-desktop.svg') center no-repeat;
      background-size: contain;
    }
  }

  &-hamburger {
    padding: 0;
    z-index: 300;

    @include laptopsDPI {
      display: none;
    }

    .hamburger-box {
      width: 18px;
      height: 12px;

      .hamburger-inner {
        width: 18px;
        height: 2px;

        &:before {
          top: -4px;
        }

        &:after {
          bottom: -4px;
        }

        &:before, &:after {
          width: 18px;
          height: 2px;
        }
      }
    }

    &.hamburger--spin.is-active {
      .hamburger-inner {
        background-color: $white;

        &:after {
          transform: rotate(-90deg) translateX(4px);
          background-color: $white;
        }

      }
    }
  }

  &-progress {
    width: 100%;
    height: 4px;

    #progress-cont {
      height: 100%;
    }

    #progress-bar {
      height: 100%;
      background-color: $blue;
    }
  }
}

.nav-list {
  @include flex(column, center, center);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $blue;
  opacity: .9;
  transition: all .3s ease-in;
  box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.5);

  &--collapse {
    z-index: 0;
    transform: translateY(calc(-100vh + 40px));
    background-color: transparent;
  }

  @include laptopsDPI {
    @include flex(row, flex-start, center);
    position: static;
    transform: none;
    box-shadow: none;
  }

  &__item {
    padding: 15px 0;
    cursor: pointer;

    @include laptopsDPI {
      position: relative;
      margin-left: 50px;

    }

    &-link {
      color: $white;
      font-size: 28px;
      letter-spacing: .5px;
      font-weight: 700;

      &:before {
        @include laptopsDPI {
          content: '';
          position: absolute;
          bottom: 15px;
          left: 0;
          height: 2px;
          width: 0;
          background-color: $blue;
          transition: all .2s ease-in-out;
        }
      }

      &:hover:before {
        @include laptopsDPI {
          width: 100%;
        }
      }

      @include laptopsDPI {
        color: $gray;
        font-size: 14px;
        letter-spacing: .6px;
        text-transform: uppercase;
      }
    }
  }
}