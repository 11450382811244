@keyframes circle-rotation-fw {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes circle-rotation-rw {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}

.circle {
  transition: all .8s cubic-bezier(.05,.97,.46,1);
  cx: 115;
  cy: 30;
  r: 20;
  @include tablets {
    cx: 40;
  }

  &_1 {
    animation: circle-rotation-fw 3s linear infinite;
    animation-delay: .2s;
    transform-origin: 113px 28px;
    @include tablets {
      transform-origin: 38px 28px;
    }
  }
  &_2 {
    animation: circle-rotation-rw 3.5s linear infinite;
    animation-delay: .6s;
    transform-origin: 116px 31px;
    @include tablets {
      transform-origin: 44px 31px;
    }
  }
  &_3 {
    animation: circle-rotation-fw 4s linear infinite;
    animation-delay: 1.2s;
    transform-origin: 118px 32px;
    @include tablets {
      transform-origin: 38px 32px;
    }
  }
  &_4 {
    animation: circle-rotation-fw 4.5s linear infinite;
    animation-delay: 2s;
    transform-origin: 123px 30px;
    @include tablets {
      transform-origin: 45px 35px;
    }
  }
  &_5 {
    animation: circle-rotation-fw 5s linear infinite;
    animation-delay: 2.4s;
    transform-origin: 124px 29px;
    @include tablets {
      transform-origin: 40px 28px;
    }
  }
  &_6 {
    animation: circle-rotation-fw 5.5s linear infinite;
    animation-delay: 2.8s;
    transform-origin: 115px 31px;
    @include tablets {
      transform-origin: 44px 25px;
    }
  }
}

.hovered {
  .circle {
    r: 50;
    @include tablets {
      r: 70;
    }

    &_1 {
      cx: 51px;
      cy: 58px;
      transform-origin: 53px 60px;
      @include tablets {
        cx: 41px;
        cy: 133px;
        transform-origin: 44px 135px;
      }
      @include laptops {
        cx: 51px;
        cy: 133px;
        r: 65;
        transform-origin: 53px 135px;
      }
    }
    &_2 {
      cx: 51px;
      cy: 76px;
      transform-origin: 53px 80px;
      @include tablets {
        cx: 41px;
        cy: 166px;
        transform-origin: 44px 170px;
      }
      @include laptops {
        cx: 51px;
        cy: 146px;
        r: 65;
        transform-origin: 54px 150px;
      }
    }
    &_3 {
      cx: 108px;
      cy: 75px;
      r: 60;
      transform-origin: 105px 75px;
      @include tablets {
        cx: 110px;
        cy: 155px;
        r: 90;
        transform-origin: 113px 151px;
      }
      @include laptops {
        cx: 120px;
        cy: 145px;
        r: 75;
        transform-origin: 123px 141px;
      }
    }
    &_4 {
      cx: 148px;
      cy: 75px;
      r: 60;
      transform-origin: 145px 75px;
      @include tablets {
        cx: 198px;
        cy: 155px;
        r: 90;
        transform-origin: 195px 157px;
      }
      @include laptops {
        cx: 218px;
        cy: 145px;
        r: 75;
        transform-origin: 215px 147px;
      }
    }
    &_5 {
      cx: 188px;
      cy: 58px;
      transform-origin: 185px 60px;
      @include tablets {
        cx: 258px;
        cy: 136px;
        transform-origin: 260px 140px;
      }
      @include laptops {
        cx: 298px;
        r: 65;
        transform-origin: 300px 140px;
      }
    }
    &_6 {
      cx: 188px;
      cy: 76px;
      transform-origin: 190px 80px;
      @include tablets {
        cx: 258px;
        cy: 170px;
        transform-origin: 260px 171px;
      }
      @include laptops {
        cx: 298px;
        cy: 150px;
        r: 65;
        transform-origin: 300px 152px;
      }
    }
  }
}