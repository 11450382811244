

.features {
  padding: 25px 0;

  &-container {
    position: relative;
  }

  &-block {
    position: relative;
    margin-bottom: 50px;

    @include tablets {
      margin-bottom: 80px;
    }
  }

  &-media {
    @include flex(column, center, center);
    position: relative;
    width: 200px;
    height: 415px;
    margin: 0 auto 50px;

    @include tablets {
      width: 217px;
      height: 450px;
    }
    @include laptops {
      position: absolute;
      top: 0;
      left: 36px;
      width: 244px;
      height: 506px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 177px;
      height: 2px;
      background-color: #f6fbfe;

      @include tablets {
        bottom: 32px;
        width: 192px;
      }
      @include laptops {
        bottom: 38px;
        width: 214px;
      }
    }

    &__video {
      width: 90%;
      height: 85%;
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &-picture {
    @include flex(column, flex-start, flex-end);
    position: relative;
    width: 100%;
    height: 100vw;
    max-height: 780px;
    margin-bottom: 80px;

    @include tablets {
      margin-bottom: 0;
    }

    &__stripe {
      height: 100%;
      width: 25%;
      background-color: $red;

      @include tablets {
        max-width: 120px;
      }
      @include laptops {
        max-width: 230px;
      }
    }

    &__man {
      width: 95%;
      position: absolute;
      top: 0;
      right: 2%;

      @include tablets {
        width: 475px;
        height: 369px;
      }
      @include laptops {
        right: 13%;
        top: -20px;
      }
      @include laptopsDPI {
        top: 40px;
      }
    }
  }

  &-content {
    @include tablets {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding-right: 240px;
    }
    @include laptops {
      padding-right: 370px;
    }

    &__title {
      @include tablets {
        font-size: 40px;
        letter-spacing: 1.4px;
        margin-bottom: 40px;
      }
    }

    &__text {
      @include tablets {
        font-size: 20px;
        line-height: 36px;
      }
    }
  }

  &-list {
    @include flex(column, flex-start, center);
    width: 100%;

    @include tablets {
      @include flex(row, space-between, flex-start);
      flex-wrap: wrap;
    }

    @include laptops {
      max-width: 750px;
      margin: 0 auto;
    }
  }
}

.list {
  &-item {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 235px;
    padding: 80px 20px 20px;
    margin-bottom: 25px;
    transition: all .3s ease-in-out;

    @include tablets {
      max-width: 325px;
      margin-bottom: 10px;
    }
    @include laptops {
      max-width: 365px;
      margin-bottom: 40px;
    }
    @include laptopsDPI {
      padding: 80px 20px 20px;
    }

    &:hover {
      color: $white;
    }

    &__text {
      text-align: center;
      pointer-events: none;

      @include tablets {
        font-size: 20px;
        line-height: 36px;
        text-align: left;
      }
    }
    &__svg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      overflow: visible;

      &--blue {
        & .circle {
          fill: $blue;
        }
      }

      &--red {
        & .circle {
          fill: $red;
        }
      }

      &--yellow {
        & .circle {
          fill: $yellow;
        }
      }

      &--green {
        & .circle {
          fill: $green;
        }
      }
    }

    &_1 {
      @include circle-animation-delay(0s);
    }

    &_2 {
      @include circle-animation-delay(1s);
    }

    &_3 {
      @include circle-animation-delay(2s);
    }

    &_4 {
      @include circle-animation-delay(3s);
    }

    &.hovered {
      padding: 20px;
      @include tablets {
        padding: 80px 20px 20px;
      }
    }
  }
}