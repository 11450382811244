.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes blue-girl {
  0% {
    transform: rotateZ(-60deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-60deg);
  }
}

@keyframes green-man {
  0% {
    transform: translateY(-30%);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translateY(50%);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-30%);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(50%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(-30%);
    animation-timing-function: ease-in;
  }
}
