.contacts {
  padding: 25px 0 24px;

  @include tablets {
    padding: 50px 0 21px;
  }
  @include laptops {
    padding: 55px 0 33px;
  }

  &__container {
    position: relative;
    padding: 0 20px;
    @include flex(column, center, center);
  }

  &__title {
    text-align: center;
    margin-bottom: 35px;

    @include tablets {
      font-size: 48px;
      letter-spacing: 1.7px;
      line-height: 60px;
      max-width: 530px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 50px;
    max-width: 560px;
    @include tablets {
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 100px;
    }
    @include laptopsDPI {
      margin-bottom: 50px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 290px;

    @include vertical {
      max-width: 100%;
    }
    @include tablets {
      max-width: 590px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    &__row {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      @include tablets {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 25px;
      }
      @include laptopsDPI {
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__cell {
      position: relative;
      width: 250px;
      height: 50px;
      background: $white;
      box-shadow: 0 4px 8px rgba(175, 175, 175, 0.5);
      border-radius: 8px;
      margin-bottom: 25px;
      &_height {
        height: 160px;
      }

      &.active {
        opacity: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @include vertical {
        width: 100%;
        &:first-child {
          margin-bottom: 25px;
        }
      }
      @include tablets {
        margin-left: 30px;
        flex: 1;
        width: 100%;
        &:first-child {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
      @include laptopsDPI {
        margin-bottom: 80px;
      }
    }

    &__input {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 8px;
      padding-left: 16px;
      font-family: Lato;
      font-size: 14px;
      line-height: 17px;
      @include placeholder(#989898, 1, 14px, Lato)
    }

    &__area {
      margin-bottom: 33px;
    }

    &__message {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: none;
      border-radius: 8px;
      padding: 17px 16px;
      resize: none;
      outline: none;
      @include placeholder(#989898, 1, 14px, Lato)
    }

    &__submit {
      width: 150px;
      background: #708EFF;
      box-shadow: 0 4px 8px rgba(197, 197, 197, 0.5);
      border-radius: 8px;
      height: 40px;
      border: none;
      color: $white;
      font-weight: bold;
      line-height: 17px;
      cursor: pointer;
    }

    &__error {
      color: $red;
      display: none;
      &.invalid-email {
        display: block;
        position: absolute;
        top: 50px;
      }
      &.js-invalid {
        display: block;
      }
    }
  }
}

