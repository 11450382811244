.collapsed {
  //z-index: -1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .2s ease-in-out;
}

.no-scroll {
  overflow-y: hidden;
}

.form {
  &-valid {
    box-shadow: 0 4px 8px $blue;
  }

  &-invalid {
    box-shadow: 0 4px 8px $red;
  }
}

.active:before {
  @include laptopsDPI {
    width: 100%;
  }
}