@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  width: 150px;
  height: 150px;
  display: block;
  overflow: visible;
  will-change: transform;
  opacity: 1;
  transition: all .3s ease-in-out;

  &--collapsed {
    z-index: -1;
    opacity: 0;
  }
}
.loader div {
  height: 100%;
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotateLoader linear 3.5s infinite;
  border-top-color: $blue;
  border-left-color: $darkBlue;
  animation-timing-function: cubic-bezier(.55, .38, .21, .88);
  animation-duration: 3s;
  will-change: transform;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  height: 150px;
  width: 300px;
  background-color: $white;
  border-radius: 8px;
  opacity: 1;
  transition: all .3s ease-in-out;

  &-header {
    position: relative;
    height: 30px;
    box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.5);

    &__close {
      @include flex(column, center, center);
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%) rotateZ(45deg);
      z-index: 10;
      width: 15px;
      height: 15px;
      cursor: pointer;

      &-line {
        position: relative;
        width: 15px;
        height: 2px;
        background-color: $blue;

        &:before {
          content: '';
          position: absolute;
          top: -1px;
          right: 0;
          transform: rotateZ(90deg) translateX(1px);
          z-index: 20;
          width: 15px;
          height: 2px;
          background-color: $blue;
        }
      }
    }
  }

  &-body {
    position: absolute;
    top: 30px;
    left: 0;
    height: 120px;
    width: 100%;
    opacity: 1;
    pointer-events: all;
    @include flex(column, center, center);
    padding: 20px;

    &--success {
      color: $blue;
    }

    &--error {
      color: $red;
    }
  }

  &-text {
    font-size: 24px;
    text-align: center;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: $blue;
    opacity: .4;
    transition: all .3s ease-in-out;
  }
}