.goal {
  padding: 25px 0;

  @include tablets {
    padding: 25px 0 50px;
  }
  @include laptopsDPI {
    padding: 25px 0 75px;
  }

  &-container {
    position: relative;
  }

  &-picture {
    position: relative;
    width: 100%;
    height: 100vw;
    max-height: 680px;
    margin-bottom: 80px;

    @include tablets {
      margin-bottom: 0;
    }
    @include laptops {
      max-height: 880px;
    }

    &__stripe {
      height: 100%;
      width: 25%;
      background-color: $blue;

      @include tablets {
        max-width: 120px;
      }
      @include laptops {
        max-width: 230px;
      }
    }

    &__man {
      width: 95%;
      position: absolute;
      top: 0;
      left: 6%;

      @include tablets {
        top: -20px;
        width: 370px;
        height: 285px;
      }
      @include laptops {
        top: 70px;
        left: 5%;
        width: 350px;
        height: 270px;
      }
    }
  }

  &-content {
    @include tablets {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 0 36px 0 215px;
    }
    @include laptops {
      padding: 0 36px 0 515px;
    }

    &__title {
      @include tablets {
        font-size: 40px;
        letter-spacing: 1.4px;
        margin-bottom: 40px;
      }
    }

    &__text {
      @include tablets {
        font-size: 20px;
        line-height: 36px;
      }
    }
  }

  &-media {
    @include flex(column, center, center);
    position: relative;
    width: 200px;
    height: 415px;
    margin: 0 auto 50px;

    @include tablets {
      width: 217px;
      height: 450px;
    }
    @include laptops {
      position: absolute;
      top: 0;
      right: 197px;
      width: 244px;
      height: 506px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 177px;
      height: 2px;
      background-color: #f6fbfe;

      @include tablets {
        bottom: 32px;
        width: 192px;
      }
      @include laptops {
        bottom: 38px;
        width: 214px;
      }
    }

    &__video {
      width: 90%;
      height: 85%;
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

}